import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';

import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalService
} from '@azure/msal-angular';
import {
  InteractionType,
  IPublicClientApplication,
  PublicClientApplication
} from '@azure/msal-browser';
import { CxMaterialConfigProviders } from '@bbraun/cortex';
import { CxDialogModule } from '@bbraun/cortex/dialog';
import { SNACKBAR_CONFIG, SnackbarGlobalConfig } from '@bbraun/cortex/snackbar';
import { AzureInsightsModule } from '@bbraun/helpex/src/lib/azure-insights';
import { provideClarity } from 'ngx-clarity';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';
import { loginRequest, msalConfig } from './auth-config';
import { CustomRouteReuseStrategy } from './custom-route-reuses-strategy';
import { UserService } from './modules/shared/service/user/user.service';
import { SharedModule } from './modules/shared/shared.module';
import { TranslocoRootModule } from './transloco-root.module';

/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit :
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: loginRequest
  };
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();

  protectedResourceMap.set(
    environment.apiConfig.uri,
    environment.apiConfig.scopes
  );

  protectedResourceMap.set(
    environment.authApiConfig.uri,
    environment.authApiConfig.scopes
  );

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

const customSnackbarConfig: SnackbarGlobalConfig = {
  maxOpened: 5,
  closeButton: true
};

@NgModule({ declarations: [ AppComponent ],
  bootstrap: [ AppComponent ], imports: [ BrowserModule,
    AppRoutingModule,
    SharedModule,
    BrowserAnimationsModule,
    TranslocoRootModule,
    CxDialogModule,
    MatDatepickerModule,
    AzureInsightsModule ], providers: [
    { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
    ...CxMaterialConfigProviders,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    {
      provide: SNACKBAR_CONFIG,
      useValue: customSnackbarConfig
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    UserService,
    provideHttpClient(withInterceptorsFromDi()),
    provideClarity({
      enabled: environment.clarity.enable,
      projectId: environment.clarity.clarityKey,
    }),
  ] })
export class AppModule {}
